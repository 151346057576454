.logodiv {
    height: 50px;
    width: 275px;
    background: rgba(27, 32, 40, 0.97);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.mainPanel {
    background: rgba(27, 32, 40, 0.93);

}

.scaleLoader {
    height: 200px;
    margin-top: 50px;
}
