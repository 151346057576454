.budgetNotAvailablePanel {
    background: rgb(27, 29, 36);
    border-radius: 20px;
    padding: 20px;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: 20px;
}

.budgetInfoInvalidMonthNotice{
    color: #fff;
    text-align: center;
    margin: 150px 10px 10px;
    padding: 20px
}

.budgetNavInvalidMonthNotice{
    color: #fff;
    text-align: center;
    margin: 20px 10px 10px;
    padding: 20px
}

.budgetScrollablePanel {
    width: calc(100% - 735px);
    height: calc(100% - 0px);
    align-items: stretch;
    float: left;
    overflow: auto;
    margin-left: 0;
}

.viewTypeSelectionNav {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: calc(100% - 950px);
    z-index: 1;
    position: fixed;
    align-items: center;
    text-align: center;
    background: #1b1d24;
    margin-left: 100px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.budgetScrollableNav{
    width: calc(100% - 950px);
    z-index: 1;
    position: fixed;
    align-items: center;
    text-align: center;
    background: var(--color-background);
    margin-left: 80px;

    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0 4px 10px rgba(0,0,0,.5), 0 4px 10px rgba(0,0,0,.5);
}

.budgetNavigationInfoPanel{
    background-color:  var(--color-background-3);
    width: 475px;
    height: calc(100% - 10px);
    float: right;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 7px;

}

.budgetSelectedCategory{
    background-color: var(--color-background);
    border-radius: 50px 50px 7px 7px;
    width: 475px;
    height: 195px;
    bottom: 4px;
    position: fixed;
    padding: 20px;
    transition: 0.7s;
    overflow-y: hidden;
    border-top-width: 7px;
    border-top-style: solid;
    border-top-color: var(--color-border);

}

.budgetSelectedCategory:hover {
    height: 350px;
    overflow-y: auto
}

.moneyText{
    font-size: 20px;
    font-weight: bold;
    color: #1abe12;

}

.hoverText{
    font-size: 15px;
    color: #b4b4b4;
    transition: 1s
}

.budgetSelectedCategory:hover .hoverText {
    font-size: 0;
}

.linkedExpenseText{
    font-size: 0;
    color: #b4b4b4;
    transition: 1s
}

.budgetSelectedCategory:hover .linkedExpenseText {
    font-size: 15px;
}


.budgetDateSelector{
    background-color: var(--color-background);
    border-radius: 7px 7px 50px 50px;
    width: 475px;
    height: 90px;
    top: 60px;
    position: fixed;
    padding: 20px;
    transition: 0.7s;
    overflow-y: hidden;
    border-bottom-width: 7px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-border);;

}

.budgetNavPanel{
    background-color:  var(--color-background-3);
    width: 245px;
    height: calc(100% - 10px);
    float: left;
    margin: 5px;
    border-radius: 7px;
}

.budgetNavNavigationHeader{
    background-color: var(--color-background);
    border-radius: 7px 7px 25px 25px;
    width: 245px;
    height: 50px;
    top: 61px;
    position: fixed;
    padding: 5px;
    overflow-y: hidden;
    border-bottom-width: 7px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-border);

}

.budgetNavActionHeader{
    background-color: var(--color-background);
    border-radius: 7px 7px 25px 25px;
    width: 245px;
    height: 50px;
    padding: 5px;
    margin-top: 10px;
    overflow-y: hidden;
    border-bottom-width: 7px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-border);;

}
.budgetNavActionItems{
    width: 240px;
    height: auto;
    margin-top: 20px;

}
.budgetNavActionItems.action-menu-button{
    background: #00c853;
}

.budgetNavigationInfoPanelMain{
    position: fixed;
    height: calc(100% - 360px);
    width: 475px;
    margin-bottom: 10px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow-y: auto;
    margin-top: 90px;
}

.expenseCategoryPanel {
    margin-top: 5px;
    margin-right: 5px;
    background: var(--color-background);
}

.expenseCategoryRow{
    background: #30303a;
}

.expenseCategoryRow1{
    background: #30303a;
}

.expenseCategoryRow:hover{
    background: #1b1d24;
}

.expenseCategoryRow1:hover{
    background: #1b1d24;
}

.expenseCategoryTable{
    border-radius: 10px;
}
.expenseCategoryEditIconStack{
   width: 10px;

    background: #2bc800;
    display: none;
}

@media only screen and (max-width: 120px) {
    .expenseCategoryEditIconStack {
        display: none;
    }
}


.budgetCategoryPanel{
    margin-top: 10px;
    margin-right: 5px;
    background: var(--color-background);
    border-radius: 15px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border);;
    padding: 15px;
    transition: 0.3s;


}

.budget-category-header{
    background:  var(--color-background-2);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0 15px;
    width: fit-content;
    margin-left: 10px;


    border-color: #900000;

    border-top-width: 3px;
    border-top-style: solid;
    border-left-width: 1px;

    border-left-style: solid;
    border-right-width: 1px;
    border-right-style: solid;
}


.budget-income-category-header{
    background:  var(--color-background-2);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0 15px;
    width: fit-content;
    margin-left: 10px;
    border-top-width: 3px;
    border-top-style: solid;
    border-top-color: #008c0d;

    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #008c0d;

    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #008c0d;

}
.budget-category-item-panel{
    background:  var(--color-background-2);
    border-radius: 15px;
    padding: 5px 10px;

    overflow-x: auto;
}



.budget-category-item {
    background:  var(--color-background-2);
    padding: 5px 5px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
     border-bottom-color: var(--color-border-2);

    border-radius: 15px;
    transition: 0.3s;


}

.budget-category-item-panel-grid{
    display: grid;
    grid-template-columns: minmax(150px, 350px) minmax(110px, 125px) 1fr;
    grid-template-rows: 1fr;
    gap: 32px 10px;
    grid-template-areas:
    "name amount info";


}

.budget-income-category-item{
    background: #30303a;
    padding: 5px 5px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-border-2);
    border-radius: 15px;
    transition: 0.3s;
}

.budget-income-category-item:hover{
    background: var(--color-background);
    padding: 5px 10px;
    border-bottom-color: #0f0;
}

.budget-category-item:hover {
    background:  var(--color-background);
    padding: 5px 10px;
    border-bottom-color: #ff0000;
}

.budget-category-item__name {
    grid-area: name;

}

.budget-category-item__amount{
    grid-area: amount;
}
.budget-category-item__info{
    grid-area: info;
    padding: 5px;
    background: var(--color-background-3);
    border-radius: 10px;
}

.budget-category-name{
    transition: 0.3s;
}

.budget-category-name:hover{
    color: #5692be;
}

.budget-category-amount{
    color: #a80000;
    height: 30px;
    padding-left: 5px;
    padding-right: 5px;
    background: var(--color-background);
    border-radius: 5px;
    margin-left: 10px;
    font-weight: bold;
}

.budget-category-item__info__modification-button{
    background: #2bc800;
    border-radius: 5px;
    padding: 5px;
    margin-right: 5px;
}

.budget-category-item__info__feedback{
    color: #1a9102;

    padding: 5px;
    border-radius: 10px;
}

.budget-category-item__info__expense-list-avatar {
    font-weight: bold;
    font-size: 20px;
    border-width: 5px;
    border-style: solid;
    border-color: #900000;
    background: #0035fc;
}

.budget-category-item__info__edit-icon {
    color: #1e88e5;
}

.budget-category-item__info__edit-icon:hover {
    color: #99cefd;
}

.budget-category-item__info__delete-icon {
    color: #d91c1c;
}

.budget-category-item__info__delete-icon:hover {
    color: #ff9393;
}

.budget-category-modification-buttongroup{
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    justify-content: center;
    -webkit-transition: .5s all;
    opacity: 0;
    height: 0;
    transition-delay: 0.2s;
}

/*animate display of modification buttons when expenseCategory panel is hovered*/
.budgetCategoryPanel:hover .budget-category-modification-buttongroup{
    opacity: 1;
    height: 20px;
}


.budget-color-picker__color{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
}


.action-menu-button{
    background-color: #1b5e20;
}

.action-menu-button:hover{
    background: #1b1d24;
}

.summary-income-view{
    height: 65px;
    background: rgb(28,157,2);
    margin: 15px 5px;
    border-radius: 15px;
    text-align: center;
}
.summary-expense-view{
    height: 65px;
    background: rgb(168, 0, 0);
    margin: 15px 5px;
    border-radius: 15px;
    text-align: center;
}

.summary-view-amount{
    background: var(--color-background);
    width: auto;
}

.summary-balance-view{
    height: 65px;
    background: rgb(60, 60, 72);
    margin: 15px 5px;
    border-radius: 15px;
    text-align: center;
}

@media (max-width: 1300px) {
    .budgetNavigationInfoPanel{
        display: none;
    }
    .budgetScrollablePanel{
        width: calc(100% - 260px);
    }
    .budgetScrollableNav{
        width: calc(100% - 475px);
    }

}

@media (max-width: 800px) {
    .budgetNavPanel{
        display: none;
    }
    .budgetScrollableNav{
        width: calc(100% - 85px);
        margin-left: 10px;
    }
    .budgetScrollablePanel{
        width: calc(100% - 5px);
        margin-left: 5px;
    }
    .budget-category-item__info__feedback{
        display: none;
    }
    .budget-category-item__info__expense-list-avatar{
        display: none;
    }
}
